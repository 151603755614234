<template>
    <!-- Begin Banner Area -->
    <div class="banner-area banner-common">
        <div class="container banner-container">
            <div class="row">
                <div class="col-lg-6 align-self-center order-lg-1 order-2">
                    <div class="banner-content">
                        <span class="banner-category">Easy Finding System</span>
                        <h2 class="banner-title playfair-font">You Can Find Your Destination Just One Click.</h2>
                        <p class="banner-desc">That necessitates a robust ecommerce platform optimizes your store & products</p>
                        <router-link class="btn btn-custom-size sapphire-hover" to="/contact">Download Now</router-link>
                    </div>
                </div>
                <div class="col-lg-6 order-lg-2 order-1">
                    <div class="banner-img">
                        <div data-hover-only="true" class="scene">
                            <div class="expand-width" data-depth="0.2">
                                <img src="images/banner/1-1-654x619.png" alt="Banner" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <!-- End Banner Area -->
</template>
